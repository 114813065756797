import { Typography } from '@material-ui/core'
import React from 'react'
import { FlexColumn } from '../components/flexContainers'
import MainLayout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <MainLayout useAltActionButton >
    <FlexColumn fullWidth centerBox style={{padding: '5%',textAlign: 'center'}}>
      <SEO title="404: Not found" />
      <Typography variant='h2' color='primary'>Page Not Found</Typography>
      <Typography variant='h6' color='secondary'>You just hit a route that doesn&#39;t exist... the sadness.</Typography>
    </FlexColumn>
  </MainLayout>

)

export default NotFoundPage
